import React from "react";
import { Link } from "react-router-dom";
import expNav1 from "../Assets/cloud_8107806.png";
import expNav2 from "../Assets/marketing-automation (5).png";
import expNav3 from "../Assets/paper-plane (1).png";
import expNav4 from "../Assets/digital-marketing.png";
import expNav5 from "../Assets/exp4.png";
import expNav6 from "../Assets/care_9399447.png";
import expNav7 from "../Assets/checklist_9399465.png";
import expNav8 from "../Assets/setting.png";
import expNav9 from "../Assets/shopping-cart.png";
import expNav10 from "../Assets/cloud_1029878.png";
import expNav11 from "../Assets/mind_14985710.png";
import expNav12 from "../Assets/telemedicine (1).png";
import expNav13 from "../Assets/charity (4).png";
import expNav14 from "../Assets/payment.png";
import expNav15 from "../Assets/link.png";
import expNav16 from "../Assets/energy-system.png";
import expNav17 from "../Assets/signature.png";
import expNav18 from "../Assets/smart-contract.png";

const Expertise = () => {
  const expertNavData = [
    [
      {
        image: expNav1,
        title: "Sales Cloud",
        link: "",
      },

      {
        image: expNav2,
        title: "Pardot",
        link: "",
      },

      {
        image: expNav11,
        title: "Salesforce Industries",
        link: "",
      },
    ],

    [
      {
        image: expNav8,
        title: "Service Cloud",
        link: "",
      },

      {
        image: expNav9,
        title: "B2B Commerce",
        link: "",
      },

      {
        image: expNav12,
        title: "Health Cloud",
        link: "",
      },
    ],

    [
      {
        image: expNav3,
        title: "Experience Cloud",
        link: "",
      },

      {
        image: expNav13,
        title: "NPSP",
        link: "",
      },
      {
        image: expNav7,
        title: "Consumer Goods Cloud",
        link: "",
      },
    ],
    [
      {
        image: expNav5,
        title: "Data Cloud",
        link: "",
      },
      {
        image: expNav15,
        title: "FSL",
        link: "",
      },

      {
        image: expNav10,
        title: "Financial Service Cloud",
        link: "",
      },
    ],
    [
      {
        image: expNav4,
        title: "Marketing Cloud",
        link: "",
      },
      {
        image: expNav14,
        title: "CPQ & Billing",
        link: "",
      },

      {
        image: expNav6,
        title: "Loyalty Management",
        link: "",
      },
    ],
    [
      {
        image: expNav17,
        title: "Nintex - Assuresign",
        link: "",
      },

      {
        image: expNav16,
        title: "Mulesoft",
        link: "",
      },

      {
        image: expNav18,
        title: "Nintex - Drawloop",
        link: "",
      },
    ],
  ];

  return (
    <>
      <Link
        to="/"
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-ps2id-api="true"
      >
        Expertise
      </Link>

      <ul className="sub-menu sub-mega-menu">
        <li>
          <div className="mega-menu-content">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="8516"
              className="elementor elementor-8516"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-791e5814 elementor-section-full_width elementor-section-stretched ot-mega-menu elementor-section-height-default elementor-section-height-default"
                data-id="791e5814"
                data-element_type="section"
                data-settings='{"stretch_section":"section-stretched","background_background":"classNameic"}'
                style={{
                  width: "150rem",
                  height: "40rem",
                  left: "-70rem",
                  backgroundColor: "white",
                }}
              >
                <div
                  className="elementor-container elementor-column-gap-extended"
                  style={{ marginTop: "25px" }}
                >
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-63ad8af7 ot-flex-column-vertical"
                    data-id="63ad8af7"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-b265eea elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="b265eea"
                        data-element_type="section"
                        data-settings='{"background_background":"classNameic"}'
                      >
                        <div
                          className="elementor-container elementor-column-gap-extended"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {expertNavData.map((expGroup, colIndex) => (
                            <div
                              className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-737015e ot-flex-column-vertical"
                              data-id="737015e"
                              data-element_type="column"
                              key={colIndex}
                            >
                              {/* col1 */}
                              <div className="elementor-widget-wrap elementor-element-populated">
                                {expGroup.map((exp, index) => (
                                  <div
                                    className="elementor-element elementor-element-f0870e3 elementor-widget elementor-widget-iimagebox"
                                    data-id="f0870e3"
                                    data-element_type="widget"
                                    data-widget_type="iimagebox.default"
                                    key={index}
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="ot-image-box">
                                        <figure
                                        //  className="ot-image-box__img"
                                         >
                                          <img
                                            src={exp.image}
                                            title=""
                                            alt=""
                                            loading="lazy"
                                            style={{
                                              width: "6rem",
                                              height: "6rem",
                                            }}
                                          />
                                        </figure>
                                        <div className="ot-image-box__content">
                                          <h5 className="image-box-title">
                                            <div
                                              // className="title-link"
                                              style={{
                                                fontSize: "17px",
                                                fontWeight: "200",
                                              }}
                                            >
                                              {exp.title}
                                            </div>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Expertise;
