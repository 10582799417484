import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { ImLocation } from "react-icons/im";
import { MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";

const FooterMap = () => {
  const locations = [
    {
      name: "USA - North Carolina",
      phone: "+1 (252) 241-7538",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3340894.3935168358!2d-82.50166816281667!3d35.14184934692844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88541fc4fc381a81%3A0xad3f30f5e922ae19!2sNorth%20Carolina%2C%20USA!5e0!3m2!1sen!2sin!4v1729684637186!5m2!1sen!2sin",
    },
    {
      name: "IND - Jaipur",
      phone: "+91-7062618443",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14242.320657436034!2d75.8081322!3d26.8214936!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dc90058e0cc49%3A0x9cd4d83dc399c41f!2sUntangle%20it!5e0!3m2!1sen!2sin!4v1729929817760!5m2!1sen!2sin",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "50px",
        justifyContent: "space-evenly",
        alignItems: "center",
        fontWeight: "bold",
        color: "white",
        paddingY: "1.5rem",
        "@media (max-width :1230px)": {
          flexDirection: "column",
          justifyContent: "space-around",
          marginX: "1rem",
          width: "auto",
          gap: "50px",
        },
      }}
    >
      {locations.map((location, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "25px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            padding: "1rem",
            borderRadius: "8px",
            "@media (max-width :770px)": {
              flexDirection: "column",
              justifyContent: "space-around",
              width: "auto",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Link
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",

                WebkitTapHighlightColor: "transparent",
              }}
            >
              <IconButton aria-label="location">
                <ImLocation />
              </IconButton>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "#454c6c",
                  my: "1rem",
                  textAlign: "center",
                }}
              >
                {location.name}
              </Typography>
            </Link>

            <Link
              style={{
                display: "flex",
                flexDirection: "row",

                gap: "1rem",
                WebkitTapHighlightColor: "transparent",
              }}
            >
              <IconButton aria-label="phone">
                <MdPhone />
              </IconButton>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  color: "#454c6c",
                  mr: "4.5rem",
                  mt: "0.5rem",
                  textAlign: "center",
                }}
              >
                <a
                  href={`tel:${location.phone}`}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {location.phone}
                </a>
              </Typography>
            </Link>
          </Box>
          <iframe
            src={location.mapSrc}
            loading="lazy"
            style={{ border: "1px solid ", borderRadius: "8px" }}
            title={`Map of ${location.name}`}
          ></iframe>
        </Box>
      ))}
    </Box>
  );
};

export default FooterMap;
