import React from "react";

import video from "../Assets/v9.webm";
import fallbackImg from "../Assets/fallbackImage.png";
import fallbackVideo from "../Assets/v10.mp4";

const Herosection = () => {

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-f72d2fb add-margin elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="f72d2fb"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
      style={{ position: "relative", overflow: "hidden" }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        loading="lazy"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: "-1",
          filter: "brightness(90%) grayscale(20%)",
        }}
      >
        {/* WebM format */}
        {!isIOS && <source src={video} type="video/webm" />}
        {/* MP4 format for better iOS compatibility */}
        <source src={fallbackVideo} type="video/mp4" />
        {/* Fallback image if video is not supported */}
        <img
          src={fallbackImg}
          alt="Background"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </video>
      <div
        className="elementor-container elementor-column-gap-default"
        style={{
          display: "flex",
          justifyContent: "center",
          bottom: "50px",
          position: "relative",
          zIndex: "1", // Ensures content stays above the video
          padding: "2rem", // Adds padding for better mobile view
        }}
      >
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e107369 ot-flex-column-vertical"
          data-id="e107369"
          data-element_type="column"
          // style={{ position:"relative", marginTop:"10rem" }}
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-bcad938 elementor-widget elementor-widget-heading"
              data-id="bcad938"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h1
                  className="elementor-heading-title elementor-size-default"
                  style={{
                    fontSize: "3.5rem", // Default size for large screens
                    fontWeight: "500",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    position: "relative",
                    bottom: "50px",
                  }}
                >
                  <span
                    id="span1"
                    style={{
                      fontSize: "4.5rem",
                      fontWeight: "500",
                     
                    }}
                  >
                    Untangle Your Business
                  </span>
                  <br />
                  We simplify {"  "}
                  <span
                    id="span2"
                    style={{ fontWeight: "700", color: "#F37612" }}
                  >
                    CRM
                  </span>{" "}
                  for you.
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
        @media (max-width: 768px) {
          .elementor-heading-title {
            
            margin-top:8.5rem;
            font-size: 2.5rem !important; /* Adjust for tablets */
          }
          .elementor-heading-title #span1 {
            font-size: 2.8rem !important; /* Adjust for tablets */
          }
        }

       

        @media (max-width: 991px){
          .elementor-heading-title #span1 {
            
            position : relative;
            left:-2.5px;
            font-size: 3.5rem !important; /* Adjust for mobile */
          }
        }

        @media (max-width: 735px){
          .elementor-heading-title #span1 {
            
            position : relative;
            left:-2.5px;
            font-size: 2.5rem !important; /* Adjust for mobile */
          }
        }

        @media (max-width: 575px) {
          .elementor-heading-title {
            
            font-size: 1.2rem !important; /* Adjust for mobile */
            
            position : relative;
            
            margin-top : 10rem;
            left:-1.5px
            
          }
          .elementor-heading-title #span1 {
            
            position : relative;
            left:-2.5px;
            font-size: 1.5rem !important; /* Adjust for mobile */
          }
        }

        @media (max-width :390px){
        .elementor-heading-title #span1 {
            
            position : relative;
            left:-2.5px;
            font-size: 1.25rem !important; /* Adjust for mobile */
          }
        }

      `}</style>
    </section>
  );
};

export default Herosection;
