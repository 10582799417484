import React, {useState, useEffect} from "react";
import { Box, Stack } from "@mui/material";
import Section2Card from "../Card/Section2Card";
import img1 from "../Assets/thrive45.png";
import img2 from "../Assets/coming-soon_6814095.png";
import img3 from "../Assets/employee.png";
import img4 from "../Assets/web-speed-checking.png";

const Section2 = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 574);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 574);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const marginTop = isMobile ? "-30px" : "50px";

  const FactCard = [
    {
      img: img2,
      title: "Fast Turnarounds",
      content: "Speedy Solutions Delivered",
    },
    {
      img: img1,
      title: "Quick Responses",
      content: "Rapid & Efficient Response",
    },
    {
      img: img3,
      title: "Reliable Support",
      content: "Trusted Dependable Assistance",
    },
    {
      img: img4,
      title: "Optimize",
      content: "Minimize Recovery Time",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingY: "3.5rem",
      }}
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        marginTop="-6.5rem"
        zIndex="100"
      >
        {FactCard.map((val, index) => {
          return (
            <Section2Card
              key={index}
              img={val.img}
              title={val.title}
              content={val.content}
            />
          );
        })}
      </Stack>

      <section
        className="elementor-section elementor-inner-section elementor-element elementor-element-3b67964 elementor-section-boxed elementor-section-height-default elementor-section-height-default responsive"
        data-id="3b67964"
        
        data-element_type="section"
        
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-158c27d ot-flex-column-vertical"
            data-id="158c27d"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-b0d750c elementor-widget elementor-widget-heading"
                data-id="b0d750c"
                data-element_type="widget"
                data-widget_type="heading.default"
                // style={{ backgroundColor: "red" }}
                style={{
                  marginTop,
                }}
              >
                <div className="elementor-widget-container">
                  <h2
                    className="elementor-heading-title elementor-size-default"
                    style={{fontSize:"30px"}}
                  >
                    WHO <span style={{ color: "#F37612" }}>WE</span> ARE
                  </h2>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-e8bb90c elementor-widget elementor-widget-text-editor"
                data-id="e8bb90c"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <h2 style={{ fontSize: "25px", textAlign: "center" }}>
                    CRM Consulting Experts: Empowering Salesforce Success
                  </h2>
                  <p style={{ textAlign: "center" }}>
                    Untangleit CRM Solutions, where we transform complexity into
                    clarity. Our mission is to simplify your processes, letting
                    you focus on success. Offering tailored services —
                    administration, development, integration, migration,
                    debugging, and 24/7 support — we are committed to building
                    lasting, trust-based relationships. Unlock your business's
                    true potential with Untangleit CRM Solutions!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

       

      </section>
    </Box>
  );
};

export default Section2;
