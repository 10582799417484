import React , {useState, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const Section8 = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 574);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 574);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const marginTop = isMobile ? "-200px" : "0px";

  const solutions = [
    {
      tiltel: "Best Development Team!",
      content:
        "I've worked with many developers, but UntangleIt is the best! They're true collaborators, communicate clearly, and always deliver on time. I highly recommend them!",
    },
    {
      tiltel: "True Salesforce Experts",
      content:
        "UntangleIt's Salesforce expertise is unmatched. They helped us prioritize our work and really understand the platform.",
    },

    {
      tiltel: "Exceeded Expectations!",
      content:
        "UntangleIt is amazing! They're helpful, communicate well, and have a can-do attitude. Their problem-solving skills are incredible. They exceeded my expectations!",
    },
    {
      tiltel: "Seamless and Efficient",
      content:
        "Working with UntangleIt was seamless. They integrated DocuSign with Salesforce quickly and easily. Experts I can trust - I'll definitely be working with them again.",
    },
    {
      tiltel: "Fast, Creative Solutions",
      content:
        "UntangleIt found a creative and efficient solution for us. They're fast, skilled, and deliver amazing results. I was impressed with their agility and speed.",
    },
    {
      tiltel: "Key to Project Success",
      content:
        "UntangleIt was key to my project's success. They provided incredible support and were so easy to work with. Thanks for everything!",
    },
    {
      tiltel: "Outstanding Knowledge",
      content:
        "UntangleIt's knowledge and expertise are outstanding. They truly mastered our project. We'll definitely hire them again!",
    },
    {
      tiltel: "Fantastic Partnership",
      content:
        "UntangleIt was a fantastic partner in our complex Salesforce integration. They explained everything clearly and empowered us to handle updates ourselves. Super responsive and dedicated!",
    },
    {
      tiltel: "A Joy to Work With",
      content:
        "UntangleIt helped my project immensely. They were reliable, friendly, and a joy to collaborate with. A huge thank you for their support!",
    },
  ];

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-43c04fb add-margin elementor-section-boxed "
      data-id="43c04fb"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eefbc5d ot-flex-column-vertical"
          data-id="eefbc5d"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-3b67964 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="3b67964"
              data-element_type="section"
              // style={{ marginTop: "35px" }}
              style={{
                marginTop,
              }}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-158c27d ot-flex-column-vertical"
                  data-id="158c27d"
                  data-element_type="column"
                  
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-b0d750c elementor-widget elementor-widget-heading"
                      data-id="b0d750c"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                      
                    >
                      <div className="elementor-widget-container">
                        <h2
                          className="elementor-heading-title elementor-size-default"
                          style={{ fontSize: "30px" }}
                        >
                          VOICE OF OUR{" "}
                          <span style={{ color: "#F37612" }}>CUSTOMERS</span>
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-e8bb90c elementor-widget elementor-widget-text-editor"
                      data-id="e8bb90c"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <h5 style={{ fontSize: "25px", textAlign: "center" }}>
                          Trusted by Leading Brands and SMEs, We Deliver Value
                          by <br />
                          Simplifying Complex Business Processes with
                          Straightforward Solutions
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{ marginTop: "2.5rem" }}
              className="elementor-section elementor-inner-section elementor-element elementor-element-9e171d0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="9e171d0"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={1}
                  loop={true}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  {solutions.map((sols, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          key={index}
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6194b98 ot-flex-column-vertical"
                          data-id="6194b98"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-a60d6a2 ot-view-stacked ot-position-top elementor-widget elementor-widget-iiconbox"
                              data-id="a60d6a2"
                              data-element_type="widget"
                              data-widget_type="iiconbox.default"
                              style={{
                                background: "rgba(255, 255, 255, 0.2)", // slightly more opaque for stronger glass effect
                                backdropFilter: "blur(20px)", // stronger blur for more glassy look
                                WebkitBackdropFilter: "blur(20px)", // for Safari support
                                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)", // deeper shadow for better separation from background
                                border: "1px solid rgba(255, 255, 255, 0.3)", // slightly stronger border for emphasis
                                borderRadius: "15px", // smooth rounded corners
                                padding: "20px", // spacing
                              }}
                            >
                              <div className="elementor-widget-container">
                                <div className="ot-icon-box">
                                  <div className="ot-icon-box__content">
                                    <h5
                                      className="icon-box-title"
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {sols.tiltel}
                                    </h5>
                                    <div className="icon-box-divider">
                                      <span></span>
                                    </div>
                                    <div className="icon-box-des">
                                      {sols.content}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "2rem",
                      marginTop: "75px",
                    }}
                  ></div>
                </Swiper>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section8;
